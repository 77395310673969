// SITE

// Colors
$white: #fff;
$grey: #dee8ed;
$green-light: #9fe1d8;
$green-dark: #0f8595;
$blue: #0c4972;
$black: #061926;
$at: #acddd7;

// BG
$bg-green: #9fe1d8;
$bg-white: $white;

// TEXT
$color-h1: #0c4972;
$color-h1-white: $white;
$color-text: $color-h1;
$color-text-white: $white;


// Sizes
$_320: 320px;
$_360: 360px;
$_480: 480px;
$_720: 720px;
$_1024: 1024px;
$_1280: 1280px;
$_1680: 1680px;
$_1920: 1920px;
$_2400: 2400px;
// ===========
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1366px;
$xxl: 1920px;
$xxxl: 2520px;


// Fonts
$open-sans: 'Open Sans', sans-serif;
$uni-neue: 'UniNeueLight', sans-serif;

// REGISTRATION

$text-light: #fff;
$text-dark: #050917;
$light-dark: #919191;
$text-red: #fd194a;
$link-light: #fff;
$link-light-hover: #8fdaff;
$link-dark: #050917;
$link-dark-hover: #097cb5;
$svg-color: #097cb5;
$svg-color-ligh: #fff;
$error-text-color: #fd194a;
$error-border-color: #e77;


// Colors
$bg-blue: #2f4f7a;
$color-border-gray: #72777a;
$color-border-gray-light: #d3dbe0;
$bg-dd-list-hover: #eff1f5;
$color-hover: #0877cc;
$color-header-contact-icons: #9b9da2;

// Colors for alerts
$alert-danger-svg: #ff1947;
$alert-danger-color: #f51836;
$alert-danger-bg: #ffebef;
$alert-danger-border: #f2b3c1;

// Fonts
$font-roboto: 'Roboto', sans-serif;
$font-open: 'Open Sans', sans-serif;
$font-uni: 'UniNeueLight', sans-serif;

// Shadow
$shadow: 0 2px 3px rgba(0,0,0,.12);
