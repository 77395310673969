//.site-error h1{
//    background: red;
//}

.error-bg {
    padding-top: 132px;
    background: url('/images/img_head_3.jpg');
    min-height: 382px;
    background-position: center top;
    -webkit-background-size: cover;
    background-size: cover;
    @include media-query($_720) {
        padding-top: 142px;
    }
    @include media-query($_1280) {
        padding-top: 132px;
    }
    @include media-query($_1920) {
        padding-top: 209px;
    }


    p, div, h1 {
        color: #fff;
        padding-left: 24px;
        padding-right: 24px;
        @include media-query($_720) {
            padding-right: 60px;
            padding-left: 60px;
        }
        @include media-query($_1280) {
            padding-left: 80px;
            padding-right: 80px;
        }
        @include media-query($_1920) {
            padding-left: 140px;
            padding-right: 140px;
        }
    }

    p, div {
        @include media-query($_720) {
            font-size: 19px;
            line-height: 1.6;
        }
    }

    h1 {
        margin-bottom: 20px;
        letter-spacing: -0.8px;
        @include media-query($_720) {
            font-size: 64px;
        }
        @include media-query($_1024) {
            margin-bottom: 43px;
        }
        @include media-query($_1920) {
            font-size: 84px;
            line-height: 1;
        }
        @include media-query($_1920) {
            margin-bottom: 61px;
        }
    }

    .alert.alert-danger {
        font-size: 17px;
        line-height: 1.6;
        letter-spacing: 0.1px;
        margin-bottom: 20px;
        @include media-query($_720) {
            font-size: 19px;
            line-height: 1.6;
        }
    }

    p {
        margin-bottom: 10px;
        font-size: 17px;
        line-height: 1.6;
        letter-spacing: 0.1px;
        @include media-query($_720) {
            font-size: 19px;
            line-height: 1.6;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 100px;
            @include media-query($_720) {
                padding-bottom: 150px;
            }
            @include media-query($_1024) {
                padding-bottom: 450px;
            }
            @include media-query($_1280) {
                padding-bottom: 495px;
            }
            @include media-query($_1920) {
                padding-bottom: 565px;
            }
        }
    }
}