.about__wrapper {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;

	// background: #061926;
}
.about {
	padding: 0 24px 0;

	@include media-query($_720) {
		padding: 0 60px 0;
	}
	@include media-query($_1280) {
		display: flex;
		flex-direction: row;

		padding: 0 80px 0;

		justify-content: space-between;
		flex-wrap: wrap;
	}
	@include media-query($_1680) {
		padding: 0 150px 0;
	}
	@include media-query($_1920) {
		padding-bottom: 0;
	}
	&__inner {
		margin-bottom: 92px;

		@include media-query($_1280) {
			display: flex;
			flex-direction: column;

			width: 45%;
			margin-bottom: 0;

			justify-content: center;
		}
		@include media-query($_1680) {
			width: 47%;
		}
		@include media-query($_1920) {
			width: 42%;
		}
	}
	&__title {
		margin-bottom: 55px;

		color: #fff;

		@include media-query($_1280) {
			margin-bottom: 66px;
		}
		@include media-query($_1680) {
			margin-bottom: 110px;
		}
	}
	&__text {
		font-size: 24px;
		line-height: 1.5;

		margin-bottom: 30px;

		color: #fff;

		@include media-query($_720) {
			margin-bottom: 30px;
		}
		@include media-query($_1024) {
			width: 75%;
		}
		@include media-query($_1280) {
			width: 100%;
		}
		@include media-query($_1680) {
			font-size: 42px;
			line-height: 1.3;

			margin-bottom: 30px;
		}
	}
	&__link {
		font-size: 24px;
		line-height: 1.5;

		margin-bottom: 49px;

		text-decoration: underline;

		color: #9fe1d8;

		@include media-query($_1280) {
			margin-bottom: 0;
		}
		@include media-query($_1680) {
			font-size: 42px;
			line-height: 1.3;
		}
		&:hover,
		&:focus {
			text-decoration: none;

			color: #fff;
		}
	}
	&__address {
		font-size: 16px;
		line-height: 1.5;

		color: #fff;

		@include media-query($_1280) {
			display: flex;
			flex-direction: column;

			width: 24%;

			flex-wrap: wrap;
			justify-content: flex-start;
		}
		@include media-query($_1920) {
			width: 21%;
		}
		h2 {
			display: none;

			@include media-query($_1280) {
				display: block;
			}
		}
		&__inner {
			margin-bottom: 26px;

			opacity: .64;
		}
		&__link {
			margin-right: 50%;

			opacity: .64;
			color: #fff;

			@include media-query($_1280) {
				margin-right: auto;
			}
			&:last-child {
				text-decoration: underline;
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
			&:hover,
			&:focus {
				opacity: 1;
			}
		}
	}
}
