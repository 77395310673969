.greeting__wrapper {
	position: relative;
	z-index: 30;

	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	padding: 132px 24px 58px;

	@include media-query($_720) {
		padding: 142px 60px 85px;
	}
	@include media-query($_1024) {
		padding-bottom: 135px;
	}
	@include media-query($_1280) {
		padding: 132px 80px 145px;
	}
	@include media-query($_1680) {
		padding: 209px 150px 205px;
	}
	@include media-query($_1920) {
		padding-bottom: 235px;
	}

	&.greeting__wrapper_additional {
		padding-top: 87px;
		padding-bottom: 90px;

		background: #061926;

		@include media-query($_720) {
			padding-top: 147px;
			padding-bottom: 148px;
		}
		@include media-query($_1024) {
			display: none;
		}
	}
}
.greeting {
	.context-menu-one {
		cursor: pointer;
	}
	.greeting__title {
		color: #fff;

		@include media-query($_1024) {
			margin-right: 30%;
			margin-bottom: 40px;
		}
		@include media-query($_1680) {
			margin-bottom: 60px;
		}
		span {
			color: #9fe1d8;
		}
		svg {
			position: relative;
			top: -5px;
			right: -4px;

			width: 24px;
			height: 15px;

			stroke-width: 3;
			@include media-query($_720) {
				top: -11px;
				right: 0;
			}
			@include media-query($_1680) {
				top: -16px;
			}

			path {
				stroke-width: 4px;
				stroke: #9fe1d8;
			}
		}
	}
	.greeting__text {
		font-size: 18px;
		line-height: 1.5;

		display: none;

		margin-bottom: 51px;

		color: #fff;

		@include media-query($_720) {
			font-size: 20px;
		}
		@include media-query($_1024) {
			display: block;

			margin-right: 30%;
		}
		@include media-query($_1280) {
			margin-right: 40%;
		}
		@include media-query($_1680) {
			margin-right: 45%;
			margin-bottom: 75px;
		}
	}
	.greeting__btn {
		display: none;

		@include media-query($_1024) {
			display: inline-block;
		}
	}
	&.greeting_additional {
		@include media-query($_1024) {
			display: none;
		}
		.greeting__text {
			display: block;

			color: #fff;

			@include media-query($_1024) {
				display: none;
			}
		}
		.greeting__btn {
			display: inline-block;

			@include media-query($_1024) {
				display: none;
			}
		}
	}
}
