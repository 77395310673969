.context-menu-list {
	margin: 0;
	padding: 13px 0;

	opacity: .92;
	border: none;
	border-radius: 4px;
	background: #083552;
	box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
	li {
		margin: 0;
		padding: 14px 30px;

		color: #fff;
		background: none;

		@include media-query($_1280) {
			min-width: 220px;
		}


		&:hover,
		&:focus,
		&:active {
			background: none;
			span {
				opacity: 1;
			}
		}
		span {
			font-size: 16px;

			opacity: .64;
			color: #fff;

			@include media-query($_1280) {
				font-size: 20px;
			}
		}
	}
	li.active {
		span {
			opacity: 1;
		}
	}
}
