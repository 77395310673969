// ACCT.COM
@import 'main';
@import 'variables';

// SITE PART
@import 'components/reset';
@import 'components/fonts';
@import 'select2/core.scss';
@import '../../../node_modules/swiper/dist/css/swiper.min.css';
@import '../../../node_modules/onfido-sdk-ui/dist/style.css';
// @import '../../../node_modules/fullpage.js/dist/fullpage';
@import '../../../node_modules/jquery-contextmenu/src/sass/jquery.contextMenu.scss';
@import 'flag-icon-css/flag-icon.scss';
//
//// MAIN
@import 'components/mixins';
@import 'components/ios-reset';
@import 'components/typography';
@import 'components/layout';
//
@import 'components/units/btns';
@import 'components/units/field-select';
@import 'components/units/field-radio';
// @import 'components/units/field-switcher';
// @import 'components/units/tabs';
@import 'components/units/header';
@import 'components/units/footer';
//
//
//// OTHERS
@import 'components/units/s0';
@import 'components/units/context-menu';
@import 'components/units/slider';
@import 'components/units/advantage';
@import 'components/units/unit-tooltip';
@import 'components/units/unit-currency';
@import 'components/units/tarifs';
@import 'components/units/rates';
//
//
//
@import 'components/greeting/greeting';
@import 'components/greeting/greeting-swiper';
@import 'components/sections/experience';
@import 'components/sections/transfers';
@import 'components/sections/exchange';
@import 'components/sections/preview';
@import 'components/sections/about';
@import 'components/sections/progress';
@import 'components/sections/acct';
@import 'components/sections/errors';

@import 'components/helpers';

@import 'components/widget_commission';
@import 'components/widget_exchange';

@import 'components/tmp/tmp-price';

@import 'components/heading/heading';
@import 'components/content/content';
@import 'components/download/download';

