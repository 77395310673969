.tooltip {
	display: inline-block;
}

.tooltip .tooltiptext {
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;

	visibility: hidden;

	width: 120px;
	height: 0;
	padding: 21px 15px 32px;

	transition: opacity .3s;
	text-align: left;

	opacity: 0;
	background-color: #0c1f2a;

	@include media-query($_1680) {
		padding: 30px;
	}
	span {
		font-size: 13px;
		font-weight: 600;
		line-height: 19px;

		color: #fff;

		@include media-query($_1680) {
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}
	}
	p {
		font-size: 13px;
		font-weight: 400;
		line-height: 19px;

		margin-bottom: 20px;

		opacity: .64;
		color: #fff;

		@include media-query($_1680) {
			font-size: 14px;
			line-height: 21px;

			margin-bottom: 21px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.tooltip:hover .tooltiptext {
	z-index: 2000;
	right: -15px;
	bottom: 26px;
	left: -15px;

	visibility: visible;

	width: calc(100% + 30px);
	height: auto;

	opacity: 1;

	@include media-query($_1680) {
		right: -35px;
		// top: 32px;
		// bottom: auto;
		left: -35px;

		width: calc(100% + 70px);
	}
}

.tooltip-icon {
	font-weight: 600;
	line-height: 1;

	position: relative;
	top: 5px;

	display: inline-block;

	margin-left: 4px;

	text-align: center;

	color: #0a3b5c;


	&:hover {
		cursor: pointer;

		// opacity: .64;
	}
}



.tooltip__inner .tooltiptext {
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;

	visibility: hidden;

	width: 120px;
	height: 0;
	padding: 5px 0;
	padding: 21px 15px 32px;

	transition: opacity .3s;
	text-align: left;

	opacity: 0;
	background-color: #0c1f2a;

	@include media-query($_1680) {
		padding: 35px;
	}
	span {
		font-size: 13px;
		font-weight: 600;
		line-height: 19px;

		color: #fff;

		@include media-query($_1680) {
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}
	}
	p {
		font-size: 13px;
		font-weight: 400;
		line-height: 19px;

		margin-bottom: 20px;

		opacity: .64;
		color: #fff;

		@include media-query($_1680) {
			font-size: 14px;
			line-height: 21px;

			margin-bottom: 21px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.tooltip__inner:hover {
	svg {
		g {
			opacity: .64;
		}
	}
}
.tooltip__inner {
	cursor: pointer;
}
.tooltip__inner:hover .tooltiptext {
	z-index: 2000;
	right: -15px;
	bottom: 26px;
	left: -15px;

	visibility: visible;

	width: calc(100% + 30px);
	height: auto;

	opacity: 1;

	@include media-query($_1680) {
		right: -35px;
		// top: 32px;
		// bottom: auto;
		left: -35px;

		width: calc(100% + 70px);
	}
}
