h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-weight: normal;
	line-height: 1;

	margin-top: 0;
	margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter';
	font-weight: 500;
	line-height: 1.073;
}

h1,
h2 {
	font-size: 42px;
	line-height: 1.15;
	// letter-spacing: -1px;

	color: $color-h1;

	@include media-query($_720) {
		font-size: 64px;
		// line-height: 1.05;

		// letter-spacing: -1.7px;
	}
	@include media-query($_1680) {
		font-size: 84px;
		line-height: 1;

		// letter-spacing: -2.3px;
	}
	&.special {
		@include media-query($_720) {
			font-size: 64px;
			line-height: 1.13;

			// letter-spacing: -1.3px;
		}
		@include media-query($_1680) {
			font-size: 90px;
			line-height: 1;

			// letter-spacing: -1.8px;
		}
	}
}

h3 {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
	// letter-spacing: .15px;

	color: $color-h1;

	@include media-query($_720) {
		font-size: 20px;
	}
}

p {
	line-height: 1.5625;
	// letter-spacing: .1px;

	color: #0c4972;
}

p.medium {
	font-size: 18px;
	line-height: 1.5;
	// letter-spacing: .1px;

	color: $color-text;

	@include media-query($_720) {
		font-size: 20px;

		// letter-spacing: -.3px;
	}
	@include media-query($_1024) {
		// letter-spacing: -.4px;
	}
	@include media-query($_1280) {
		// letter-spacing: -.2px;
	}
	@include media-query($_1680) {
		// letter-spacing: -.3px;
	}
}

p.large {
	font-size: 24px;
	line-height: 1.5;
	// letter-spacing: -.1px;

	@include media-query($_480) {
		// letter-spacing: 0;
	}
}

a {
	text-decoration: none;

	color: #0088a3;
	&:hover {
		text-decoration: underline;
	}
}
