footer {
  padding-top: 87px;
  padding-bottom: 43px;

  background: #061926;

  @include media-query($_720) {
    padding-top: 178px;
    padding-bottom: 93px;
  }
  @include media-query($_1280) {
    padding-top: 238px;
    padding-bottom: 93px;
  }

  .footer-inner {
    @include media-query($_1024) {
      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
      justify-content: space-between;
      address {
        width: 50%;
      }
      nav {
        order: 3;
      }
      > div {
        position: relative;

        width: 49.1%;

        small:last-child {
          position: absolute;
          bottom: -28px;
          left: 0;
        }
      }
    }
    @include media-query($xl) {
      > div {
        width: 49.1%;
      }
    }
  }

  address {
    font-size: 16px;
    line-height: 1.5625;

    margin-bottom: 83px;

    @include media-query($_1024) {
      margin-bottom: 122px;
    }
    @include media-query($_1280) {
      margin-bottom: 117px;
    }

    p {
      opacity: .64;
      color: #fff;
    }

    a {
      opacity: .64;
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;

        opacity: 1;
      }

      &:last-child {
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  nav {
    margin-bottom: 29px;

    @include media-query($_480) {
      margin-bottom: 17px;
      br {
        display: none;
      }
    }
    @include media-query($_480) {
      display: flex;
      flex-direction: row;

      margin-bottom: 16px;

      justify-content: flex-start;
    }
    @include media-query($_720) {
      margin-bottom: 23px;
    }

    a {
      font-size: 14px;
      line-height: 1;

      display: inline-block;

      margin-right: auto;
      padding-top: 11px;
      padding-bottom: 10px;

      text-decoration: underline;
      // letter-spacing: .1px;

      opacity: 1;
      color: #fff;

      @include media-query($_480) {
        margin-right: 18px;
      }
      @include media-query($_720) {
        margin-right: 7px;

        &:last-child {
          margin-right: 0;
        }
      }
      @include media-query($_1024) {
        margin-right: 19px;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  small {
    font-size: 14px;
    line-height: 1.5;

    display: inline-block;

    margin-bottom: 36px;

    color: rgba(255, 255, 255, .64);

    @include media-query($_720) {
      margin-bottom: 38px;
    }
    @include media-query($_1280) {
      width: 80%;
    }

    span {
      text-decoration: underline;

      color: #fff;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
