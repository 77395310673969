
.radio {
	position: relative;

	margin-right: 12px;
	padding-left: 0;

	cursor: pointer;
	span:last-child {
		font-size: 18px;
		font-weight: 500;
		line-height: 1;

		color: #0f8595;

		@include media-query($_1680) {
			font-size: 20px;
		}
	}
}

.radio__input {
	position: absolute;

	visibility: hidden;

	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	-webkit-appearance: none;
	   -moz-appearance: none;
	    -ms-appearance: none;
	     -o-appearance: none;
	        appearance: none;
}

.radio__box {
	position: absolute;
	right: 0;
	bottom: -9px;
	left: 0;

	overflow: hidden;

	width: 100%;
	height: 1px;

	opacity: 0;
	background-color: white;
}


.radio__input:checked + .radio__box {
	opacity: 1;
	background-color: #507c99;
}
.radio__input:checked + .radio__box + span {
	color: #0c4972;
}
