body {
	font-family: 'Inter';
	font-weight: 400;

	transition: background .3s ease;
}

.main {
	display: flex;
	flex-direction: column;

	height: 100%;
}
.content {
	position: relative;

	overflow: hidden;
	flex: 1 0 auto;
}
.footer {
	flex: 0 0 auto;
}
.calcs {
	min-height: 1000px;
}
.container {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 24px;
	padding-left: 24px;

	@include media-query($_720) {
		padding-right: 60px;
		padding-left: 60px;
	}
	@include media-query($_1280) {
		padding-right: 80px;
		padding-left: 80px;
	}
	@include media-query($_1680) {
		padding-right: 150px;
		padding-left: 150px;
	}
}
