.select2-selection--single {
	line-height: 35px;

	position: relative;

	height: 35px;

	transition: all .2s;

	border: 1px solid #b6bcc1;
	border-radius: 0;
	background-color: #fff;

	&:focus {
		border-color: #2193cc;
		outline: none;
		box-shadow: 0 0 8px 0 #c1d4ee;

		&:hover {
			border-color: #2193cc;
			outline: none;
			box-shadow: 0 0 8px 0 #c1d4ee;
		}
		.select2-selection__rendered {
			opacity: 1;
		}
	}
	&:hover {
		border-color: #909599;
		.select2-selection__rendered {
			opacity: 1;
		}
	}

	.select2-selection__rendered {
		font-size: 13px;
		line-height: 35px;

		margin-top: -1px;
		padding-right: 13px;
		padding-left: 13px;

		color: #050917;
		.flag-icon {
			margin-right: 10px;
		}
	}

	.select2-selection__clear {
		font-weight: bold;

		float: right;

		cursor: pointer;
	}

	.select2-selection__placeholder {
		line-height: 1;

		color: rgba(5, 9, 23, .5);
	}

	.select2-selection__arrow {
		position: absolute;
		top: 50%;
		right: 12px;

		width: 12px;
		height: 7px;
		margin-top: -4px;

		transform: scale(1, -1);

		svg {
			position: absolute;
			top: 0;
			left: 0;

			width: 11px;
			height: 6px;

			fill: #333;
		}
	}
}

&[dir='rtl'] {
	.select2-selection--single {
		.select2-selection__clear {
			float: left;
		}

		.select2-selection__arrow {
			right: auto;
			left: 1px;
		}
	}
}

&.select2-container--disabled {
	.select2-selection--single {
		cursor: default;

		background-color: #eee;

		.select2-selection__clear {
			display: none;
		}
	}
}

&.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			transform: scale(1, 1);
		}
	}
}
