.scene2,
.scene3,
.scene4,
.scene5,
.scene6,
.scene7,
.scene8 {
	.experience__wrapper,
	.transfers__wrapper,
	.exchange__wrapper,
	.preview__wrapper,
	.about__wrapper,
	.progress__wrapper,
	.acct__wrapper {
		padding-top: 92px !important;
		padding-bottom: 92px !important;

		@include media-query($_720) {
			padding-top: 155px !important;
			padding-bottom: 155px !important;
		}
		@include media-query($_1280) {
			padding-top: 110px !important;
			padding-bottom: 110px !important;
		}
		@include media-query($_1680) {
		}
	}
}
.scene7 .progress__wrapper {
	@include media-query($_1280) {
		padding-bottom: 180px !important;
	}
	@include media-query($_1680) {
		padding-bottom: 240px !important;
	}
}
.scene8 .acct__wrapper {
	@include media-query($_1680) {
		padding-top: 140px !important;
		padding-bottom: 140px !important;
	}
}

.scene2 .experience__wrapper {
	@include media-query($_1680) {
		padding-top: 200px !important;
	}
}
.scene8 {
	background: #a6ddd6;
}

.hidden {
	display: none!important;
}
#yii-debug-toolbar {
	display: none!important;
}
