&--text {
  padding-top: 48px;
  padding-bottom: 48px;
  background: #fff;
  @include media-query($_720) {
    padding-top: 78px;
    padding-bottom: 78px;
  }
  @include media-query($_1920) {
    padding-top: 118px;
    padding-bottom: 118px;
  }

  a.content__link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  b {
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  .content__inner {
    @extend %container;
    @include media-query($_1024) {
      max-width: 710px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .content__p {
    color: #000;
    margin-bottom: 25px;

    &--uppercase {
      text-transform: uppercase;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--sticky-bottom {
      margin-bottom: 0;
    }
  }

  .content__p + .content__h2,
  .content__ul + .content__h2 {
    margin-top: 42px;
    @include media-query($_720) {
      margin-top: 74px;
    }
  }

  .content__h2 + .content__h3 {
    margin-top: 40px;
    @include media-query($_720) {
      margin-top: 71px;
    }
  }

  .content__p + .content__h3 {
    margin-top: 41px;
    @include media-query($_720) {
      margin-top: 73px;
    }
  }

  .content__h2 {
    color: #000;
    font-size: 23px;
    line-height: 1.260;
    letter-spacing: -.5px;
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .content__h3 {
    color: #000;
    font-size: 18px;
    line-height: 1.3333333;
    letter-spacing: -.4px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .content__ul {

    line-height: 1.5625;
    color: #000;
    margin-bottom: 25px;

    &--alpha {
      list-style-type: none;
      counter-reset: listStyleAlpha;

      li {
        position: relative;
        padding-left: 36px;
        counter-increment: listStyleAlpha;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: "(" counter(listStyleAlpha, lower-alpha) ")";
        }
      }
    }

    &--dotted {
      list-style-type: none;

      > li {
        padding-left: 21px;
        margin-bottom: 25px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: black;
          position: absolute;
          left: 2px;
          top: 11px;
        }
      }
    }

    &--numeric {
      list-style-type: none;
      counter-reset: listStyleNumeric;

      > li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        counter-increment: listStyleNumeric;
        margin-bottom: 25px;
        position: relative;
        padding-left: 36px;

        &:before {
          position: absolute;
          left: 0;
          top: 0;

          content: counter(listStyleNumeric, numeric) ".";
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--dotted-compact {
      list-style-type: none;

      > li {
        padding-left: 21px;
        margin-bottom: 0;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: black;
          position: absolute;
          left: 2px;
          top: 11px;
        }
      }
    }

    &--dotted {
      > li {
        > .content__ul--dotted-compact {
          margin-left: 3px;
          margin-top: 25px;
          margin-bottom: 50px;
          @include media-query($_720) {
            margin-bottom: 25px;
          }
        }

        &:last-child {
          > .content__ul--dotted-compact {
            margin-bottom: 0;
          }
        }
      }
    }

    &--dotted-compact {
      > li {
        > .content__ul--dotted-compact {
          margin-left: 3px;
        }

        &:last-child {
          > .content__ul--dotted-compact {
            margin-bottom: 0;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .content__table {
    border-collapse: collapse;
    border: 1px solid #000;
    height: 464px;
    width: 100%;
    font-size: 14px;

    thead {
      text-align: center;
      border: 1px solid #000;

      tr, td {
        border: 1px solid #000;
        vertical-align: center;
      }

      td {
        padding: 5px;
      }
    }

    tbody {
      border: 1px solid #000;

      tr, td {
        text-align: center;
        border: 1px solid #000;
        vertical-align: center;
      }

      td {
        padding: 5px;
      }

      td:first-child {
        text-align: left;
      }
    }
  }

  .content__table-wrapper {
    padding-top: 26px;
    padding-bottom: 39px;
    width: 100%;
    overflow-y: scroll;
    @include media-query($_720) {
      padding-bottom: 71px;
    }
    @include media-query($_1024) {
      width: 956px;
      margin-left: -123px;
    }
  }
}