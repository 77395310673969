.select2-container--acct_light {
	@import 'single';
	@import 'multiple';

	&.select2-container--open.select2-container--above {
		.select2-selection--single,
		.select2-selection--multiple {
			border-color: #0c4972;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	&.select2-container--open.select2-container--below {
		.select2-selection--single,
		.select2-selection--multiple {
			border-color: #0c4972;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.select2-search--dropdown {
		.select2-search__field {
			border: 1px solid #aaa;
		}
	}

	.select2-search--inline {
		.select2-search__field {
			border: none;
			outline: 0;
			background: transparent;
			box-shadow: none;

			-webkit-appearance: textfield;
		}
	}

	.select2-results > .select2-results__options {
		overflow-y: auto;

		max-height: 290px;
	}

	.select2-results__option {
		font-size: 20px;

		padding: 8px;

		color: #061926;
		.flag-icon {
			margin-right: 10px;
		}
		&:hover {
			color: #0f8595;
			background: #dee8ed;
		}
		&[role=group] {
			padding: 0;
		}

		&[aria-disabled=true] {
			color: #999;
		}

		&[aria-selected=true] {
			color: #0f8595;
		}

		.select2-results__option {
			padding-left: 1em;

			.select2-results__group {
				padding-left: 0;
			}

			.select2-results__option {
				margin-left: -1em;
				padding-left: 2em;

				.select2-results__option {
					margin-left: -2em;
					padding-left: 3em;

					.select2-results__option {
						margin-left: -3em;
						padding-left: 4em;

						.select2-results__option {
							margin-left: -4em;
							padding-left: 5em;

							.select2-results__option {
								margin-left: -5em;
								padding-left: 6em;
							}
						}
					}
				}
			}
		}
	}

	.select2-results__option--highlighted[aria-selected] {
		color: #0f8595;
		background-color: #eff1f5;
	}

	.select2-results__group {
		display: block;

		padding: 6px;

		cursor: default;
	}
}
