//////////////////////////////
// Generalized Media Query Mixin
//
// Requires Sass 3.2+
// Until released:
// (sudo) gem install sass --pre
//////////////////////////////
@mixin media-query($value, $operator: 'min-width', $query: 'screen') {
	@media #{$query} and (#{$operator}: #{$value}) {
		@content;
	}
}

@include media-query($sm) {
}
@include media-query($md) {
}
@include media-query($lg) {
}
@include media-query($xl) {
}
@include media-query($xxl) {
}

.select2-container {
	position: relative;

	display: inline-block;

	box-sizing: border-box;
	margin: 0;

	vertical-align: middle;

	@import 'single';
	@import 'multiple';
}

@import 'dropdown';

.select2-close-mask {
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;

	display: block;

	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	margin: 0;
	padding: 0;

	opacity: 0;
	border: 0;
	// styles required for IE to work
	background-color: #fff;

	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	position: absolute !important;

	overflow: hidden !important;
	clip: rect(0 0 0 0) !important;

	width: 1px !important;
	height: 1px !important;
	margin: -1px !important;
	padding: 0 !important;

	border: 0 !important;
}

@import 'theme/acct_dark/layout';
@import 'theme/acct_light/layout';
@import 'theme/acct_registration/layout';
