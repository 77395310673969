.progress__wrapper {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;

	// background: #dee8ed;
}
.progress {
	padding: 0 24px 0;

	@include media-query($_720) {
		padding: 0 60px 0;
	}
	@include media-query($_1280) {
		display: flex;
		flex-direction: row;

		padding: 0 80px 0;

		flex-wrap: wrap;
	}
	@include media-query($_1680) {
		padding: 0 150px 0;
	}
	@include media-query($_1920) {
		padding-bottom: 0;
	}
	&__inner {
		margin-bottom: 63px;

		@include media-query($_720) {
			margin-bottom: 92px;
		}
		@include media-query($_1280) {
			width: 45%;
			margin-bottom: 230px;
		}
		@include media-query($_1680) {
			margin-bottom: 240px;
		}
		@include media-query($_1920) {
		}
	}
	&__title {
		margin-bottom: 55px;

		@include media-query($_1280) {
			margin-bottom: 83px;
		}
		@include media-query($_1680) {
			margin-bottom: 78px;
		}
	}
	&__text {
		font-size: 18px;
		line-height: 1.5;

		@include media-query($_720) {
			font-size: 20px;
		}
		@include media-query($_1024) {
			width: 75%;
		}
		@include media-query($_1280) {
			width: 100%;
		}
		@include media-query($_1680) {
		}
	}
	&__img__wrapper {
		margin-bottom: 12px;

		@include media-query($_720) {
			margin-bottom: 21px;
		}
		@include media-query($_1280) {
			position: relative;

			width: 55%;
		}
	}
	&__img {
		width: 607px;
		margin-left: -15px;

		@include media-query($_720) {
			width: 826px;
			margin-left: -25px;
		}
		@include media-query($_1024) {
			width: 786px;
		}
		@include media-query($_1280) {
			position: absolute;
			top: -144px;
			left: 50%;

			margin-left: -242px;
		}
		@include media-query($_1680) {
			top: -79px;

			margin-left: -312px;
		}
		@include media-query($_1920) {
			top: -131px;

			margin-left: -339px;
		}
	}
	&__list {
		&__wrapper {
			@include media-query($_1280) {
				position: relative;

				display: flex;
				flex-direction: row;

				width: 100%;

				justify-content: space-between;
				flex-wrap: nowrap;
				&:before {
					position: absolute;
					top: 46px;
					right: 0;
					left: 0;

					display: block;

					height: 1px;

					content: '';

					background: #0c4972;
				}
			}
		}
		&__inner {
			margin-bottom: 63px;

			@include media-query($_720) {
				margin-bottom: 93px;
			}
			@include media-query($_1280) {
				width: 33.3333333%;
				margin-bottom: 0;
				&:nth-child(2) {
					margin-right: 20px;
					margin-left: 20px;
				}
			}
			@include media-query($_1680) {
				&:nth-child(2) {
					margin-right: 5px;
					margin-left: 5px;
				}
			}
			@include media-query($_1920) {
				&:nth-child(2) {
					margin-right: 80px;
					margin-left: 80px;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
			svg {
				position: absolute;
				top: 0;
				left: 0;

				display: inline-block;

				width: 20px;
				height: 20px;
			}
		}
		&__title {
			position: relative;

			display: block;

			margin-bottom: 48px;
			padding-bottom: 30px;

			color: #0c4972;
			border-bottom: 1px solid #0c4972;

			@include media-query($_720) {
				margin-bottom: 60px;
			}
			@include media-query($_1280) {
				margin-bottom: 61px;
				padding-left: 36px;

				border-bottom: none;
			}
			svg {
				display: none;

				@include media-query($_1280) {
					top: -1px;

					display: inline-block;
				}
			}
		}
		&__item {
			font-size: 15px;
			line-height: 1.4;

			position: relative;

			margin-bottom: 21px;
			padding-left: 36px;

			color: #0c4972;

			@include media-query($_1280) {
				padding-right: 35%;
			}
			@include media-query($_1680) {
				padding-right: 15%;
			}
			@include media-query($_1920) {
				padding-right: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
