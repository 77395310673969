.select2-container--acct_light {
	.select2-dropdown {
		border: 1px solid #e0d3d3;
		background-color: #fff;
		box-shadow: 0 2px 3px rgba(0, 0, 0, .12);
	}
}

.select2-container--acct_dark {
	.select2-dropdown {
		border: 1px solid #304a6c;
		background-color: #17355b;;
	}
}

.select2-dropdown {
	position: absolute;
	z-index: 1051;
	left: -100000px;

	display: block;

	box-sizing: border-box;
	width: 100%;

	border: 1px solid #aaa;
	border-radius: 3px;
	background-color: white;
}

.select2-results {
	display: block;
}

.select2-results__options {
	margin: 0;
	padding: 0;

	list-style: none;
}

.select2-results__option {
	padding: 6px;

	-webkit-user-select: none;
	        user-select: none;
	&[aria-selected] {
		cursor: pointer;
	}
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	top: -4px;

	opacity: 1;
}

.select2-container--open .select2-dropdown--below {
	top: 4px;

	opacity: 1;
}

.select2-search--dropdown {
	display: block;

	padding: 4px;
	.select2-search__field {
		box-sizing: border-box;
		width: 100%;
		padding: 4px;
		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}
	&.select2-search--hide {
		display: none;
	}
}
