.s0 {
	position: absolute;
	z-index: -2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: block;

	transition: background .3s ease;

	div {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: block;
	}
}

.s0.a1 {
	background: #061926;
}
.s0.a2 {
	background: #061926;
}
.s0.a3 {
	background: #dee8ed;
}
.s0.a4 {
	background: #fff;
}
.s0.a5 {
	background: #dee8ed;
}
.s0.a6 {
	background: #061926;
}
.s0.a7 {
	background: #dee8ed;
}
.s0.a8 {
	background: #fff;
}
.s0.a9 {
	background: #fff;
}

.scene-inner {
	width: 100%;
	height: 385px;

	@include media-query($_480) {
		height: 335px;
	}
	@include media-query($_720) {
		height: 450px;
	}
	@include media-query($_1024) {
		height: 780px;
	}
	@include media-query($_1280) {
		height: 800px;
	}
	@include media-query($_1680) {
		height: 1080px;
	}
	@include media-query($_2400) {
		height: 1440px;
	}
}
.swiper-main {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	width: 100%;
	height: 385px;

	@include media-query($_480) {
		height: 335px;
	}
	@include media-query($_720) {
		height: 450px;
	}
	@include media-query($_1024) {
		height: 780px;
	}
	@include media-query($_1280) {
		height: 800px;
	}
	@include media-query($_1680) {
		height: 1080px;
	}
	@include media-query($_2400) {
		height: 1440px;
	}
	.slide-1,
	.slide-2,
	.slide-3,
	.slide-4 {
		display: block;

		width: 100%;
		height: 385px;

		background-position: center center;
		background-size: cover;

		@include media-query($_480) {
			height: 335px;
		}
		@include media-query($_720) {
			height: 450px;
		}
		@include media-query($_1024) {
			height: 780px;
		}
		@include media-query($_1280) {
			height: 800px;
		}
		@include media-query($_1680) {
			height: 1080px;
		}
		@include media-query($_2400) {
			height: 1440px;
		}
	}
	.slide-1 {
		background-image: url('/images/img_head_3.jpg');
	}
	.slide-2 {
		background-image: url('/images/img_head_1.jpg');
	}
	.slide-3 {
		background-image: url('/images/img_head_2.jpg');
	}
	.slide-4 {
		background-image: url('/images/img_head_4.jpg');
	}
}

.s1,
.s2,
.s3,
.s4,
.s5,
.s6,
.s7,
.s8,
.s9 {
	z-index: 10;
}
.s9 {
	background: #a6ddd6;
}

.s2,
.s3,
.s4,
.s5,
.s6,
.s7 {
	transition: all .4s ease;

	opacity: 0;
	background: none;

	&.shown {
		opacity: 1;
	}
}

.s1.shown + .s2 {
	opacity: 1;
}
.s0.a8 {
	background: #dee8ed;
}
