.advantages__wrapper {
	position: relative;

	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	padding: 92px 24px 96px;

	@include media-query($_720) {
		padding: 154px 60px;
	}
	@include media-query($_1280) {
		position: absolute;
		z-index: 50;
		top: 110px;
		right: 80px;

		width: 360px;
		margin: 0;
		padding: 42px 40px 44px 40px;

		background: #0c1f2a;
	}
	@include media-query($_1680) {
		top: 180px;
		right: 150px;

		width: 425px;
		padding: 58px 60px 64px;
	}
	@include media-query($_1920) {
		right: auto;
		left: calc(50% + 385px);

		width: 425px;
	}
	&:before {
		position: absolute;

		content: '';
	}


	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: block;

		content: '';

		background: #0c1f2a;

		@include media-query($_1280) {
			display: none;
		}
	}
}


.advantages {
	position: relative;
	z-index: 20;

	@include media-query($_1680) {
	}
	&__list {
		position: relative;
		z-index: 20;

		margin-bottom: 65px;

		@include media-query($_720) {
			margin-bottom: 90px;
		}
		@include media-query($_1280) {
			margin-bottom: 48px;
		}
		@include media-query($_1680) {
			margin-bottom: 61px;

			text-align: left;
		}


		&__item {
			margin-bottom: 34px;

			@include media-query($_1680) {
				margin-bottom: 36px;

				text-align: left;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__title {
			font-size: 16px;

			display: block;

			margin-bottom: 5px;

			color: #9fe1d8;

			@include media-query($_1680) {
				line-height: 25px;

				margin-bottom: 0;
			}
		}
		&__text {
			font-size: 16px;
			line-height: 1.56;

			display: block;

			color: #fff;

			@include media-query($_1680) {
			}
		}
	}
	&__text {
		font-size: 14px;
		line-height: 1.5;

		margin-bottom: 32px;

		opacity: .64;
		color: #fff;

		@include media-query($_1680) {
			font-size: 16px;

			text-align: left;
		}
	}
	&__img {
		&.logo1 {
			position: relative;
			top: 6px;

			display: inline-block;

			width: 142px;
			margin-right: 30px;

			@include media-query($_720) {
				margin-right: 30px;
			}
			@include media-query($_1280) {
				margin-right: 30px;
			}
			@include media-query($_1680) {
				margin-right: 30px;
				margin-left: 0;
			}
		}
		&.logo2 {
			display: inline-block;

			width: 94px;
		}
	}
	&__logo{
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		&__item{
			opacity: 1;
			&:first-child{
				margin-right: 36px;
			}
		}
	}
}
