.rates {
	max-width: 277px;
	margin-bottom: 64px;
	@media screen and (min-width: 576px) {
		max-width: 366px;
	}
	@media screen and (min-width: 768px) {
		max-width: 486px;
		margin-bottom: 32px;
	}
	.rates-header {
		display: flex;
		flex-direction: row;

		justify-content: flex-start;
		flex-wrap: nowrap;
		.commission-box {
			margin-right: 30px;
			span {
				font-size: 14px;
				line-height: 1;

				display: block;
				&.commission-title {
					margin-bottom: 6px;

					opacity: .3;
					color: #fff;
				}
				&.commission {
					color: green;
				}
			}
		}
		.ammount-box {
			span {
				font-size: 14px;
				line-height: 1;

				display: block;
				&.ammount-title {
					margin-bottom: 6px;

					opacity: .3;
					color: #fff;
				}
				&.ammount {
					color: green;
				}
			}
		}
	}
	.rates-sm__body {
		display: flex;
		flex-direction: row;

		height: 188px;
		padding-bottom: 18px;

		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-end;
		@media screen and (min-width: 768px) {
			display: none;
		}
		.rates-sm__unit {
			width: 6px;
			height: 100px;

			border-radius: 3px;
			background: green;
			&:nth-child(1) {
				height: 100px;
			}
			&:nth-child(2) {
				height: 93px;
			}
			&:nth-child(3) {
				height: 86px;
			}
			&:nth-child(4) {
				height: 79px;
			}
			&:nth-child(5) {
				height: 72px;
			}
			&:nth-child(6) {
				height: 65px;
			}
			&:nth-child(7) {
				height: 58px;
			}
			&:nth-child(8) {
				height: 51px;
			}
			&:nth-child(9) {
				height: 44px;
			}
			&:nth-child(10) {
				height: 37px;
			}
			&:nth-child(11) {
				height: 30px;
			}
			&:hover {
				cursor: pointer;

				background: #fff;
			}
			&.active {
				height: 208px;
			}
		}
	}
	.rates__body {
		display: flex;
		flex-direction: row;

		height: 188px;
		padding-bottom: 18px;

		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-end;
		.rates-unit {
			width: 6px;
			height: 100px;

			border-radius: 3px;
			background: green;
			&:nth-child(1) {
				height: 100px;
			}
			&:nth-child(2) {
				height: 98px;
			}
			&:nth-child(3) {
				height: 96px;
			}
			&:nth-child(4) {
				height: 94px;
			}
			&:nth-child(5) {
				height: 92px;
			}
			&:nth-child(6) {
				height: 90px;
			}
			&:nth-child(7) {
				height: 88px;
			}
			&:nth-child(8) {
				height: 86px;
			}
			&:nth-child(9) {
				height: 84px;
			}
			&:nth-child(10) {
				height: 82px;
			}
			&:nth-child(11) {
				height: 80px;
			}
			&:nth-child(12) {
				height: 78px;
			}
			&:nth-child(13) {
				height: 76px;
			}
			&:nth-child(14) {
				height: 74px;
			}
			&:nth-child(15) {
				height: 72px;
			}
			&:nth-child(16) {
				height: 70px;
			}
			&:nth-child(17) {
				height: 68px;
			}
			&:nth-child(18) {
				height: 66px;
			}
			&:nth-child(19) {
				height: 64px;
			}
			&:nth-child(20) {
				height: 62px;
			}
			&:nth-child(21) {
				height: 60px;
			}
			&:nth-child(22) {
				height: 58px;
			}
			&:nth-child(23) {
				height: 56px;
			}
			&:nth-child(24) {
				height: 54px;
			}
			&:nth-child(25) {
				height: 52px;
			}
			&:nth-child(26) {
				height: 50px;
			}
			&:nth-child(27) {
				height: 48px;
			}
			&:nth-child(28) {
				height: 46px;
			}
			&:nth-child(29) {
				height: 44px;
			}
			&:nth-child(30) {
				height: 42px;
			}
			&:nth-child(31) {
				height: 40px;
			}
			&:nth-child(32) {
				height: 38px;
			}
			&:nth-child(33) {
				height: 36px;
			}
			&:nth-child(34) {
				height: 34px;
			}
			&:nth-child(35) {
				height: 32px;
			}
			&:nth-child(36) {
				height: 30px;
			}
			&:nth-child(37) {
				height: 28px;
			}
			&:nth-child(38) {
				height: 26px;
			}
			&:nth-child(39) {
				height: 24px;
			}
			&:nth-child(40) {
				height: 22px;
			}
			&:nth-child(41) {
				height: 20px;
			}
			&:hover {
				cursor: pointer;

				background: #fff;
			}
			&.active {
				height: 208px;
			}
		}
	}
	.rates__body {
		display: none;
		@media screen and (min-width: 768px) {
			display: flex;
		}
	}
	.comissions-lines {
		display: none;
		@media screen and (min-width: 768px) {
			line-height: 0;

			position: relative;

			display: block;

			width: 100%;
			height: 0;
			.commisiion_min {
				position: absolute;
				bottom: 39px;

				display: block;

				width: calc(100% + 27px);
				height: 1px;

				background: rgba(182, 189, 194, .2);
				&:after {
					font-size: 14px;
					line-height: 1;

					position: absolute;
					top: -8px;
					right: -37px;

					display: inline-block;

					content: '0.2%';

					opacity: .38;
					color: #fff;
				}
			}
			.commisiion_max {
				position: absolute;
				bottom: 117px;

				display: block;

				width: calc(100% + 27px);
				height: 1px;

				background: rgba(182, 189, 194, .2);
				&:after {
					font-size: 14px;
					line-height: 1;

					position: absolute;
					top: -8px;
					right: -37px;

					display: inline-block;

					content: '0.8%';

					opacity: .38;
					color: #fff;
				}
			}
		}
	}
	.rates-footer {
		display: flex;
		flex-direction: row;

		justify-content: space-between;
		span {
			font-size: 14px;
			line-height: 1;

			opacity: .38;
			color: #fff;
		}
		@media screen and (min-width: 768px) {
		}
	}
}
