
.select2-container--acct_registration {
	@import 'single';
	@import 'multiple';

	.select2-search--dropdown {
		.select2-search__field {
			border: 1px solid #d3dbe0;
		}
	}

	.select2-search--inline {
		.select2-search__field {
			border: none;
			outline: 0;
			background: transparent;
			box-shadow: none;

			-webkit-appearance: textfield;
		}
	}

	.select2-results > .select2-results__options {
		overflow-y: auto;

		max-height: 280px;
	}

	.select2-results__option {
		font-size: 13px;
		font-weight: 400;
		line-height: 1;

		position: relative;

		display: block;
		overflow: hidden;

		padding: 11px 13px;

		cursor: pointer;
		white-space: nowrap;
		text-overflow: ellipsis;

		color: #333;
		&:hover {
			color: #0877cc;
			background: #eff1f5;
		}
		&[role=group] {
			padding: 0;
		}

		&[aria-disabled=true] {
			color: #0877cc;
			background: #eff1f5;
		}

		&[aria-selected=true] {
			color: #0877cc;
		}

		.select2-results__option {
			padding-left: 1em;

			.select2-results__group {
				padding-left: 0;
			}

			.select2-results__option {
				margin-left: -1em;
				padding-left: 2em;

				.select2-results__option {
					margin-left: -2em;
					padding-left: 3em;

					.select2-results__option {
						margin-left: -3em;
						padding-left: 4em;

						.select2-results__option {
							margin-left: -4em;
							padding-left: 5em;

							.select2-results__option {
								margin-left: -5em;
								padding-left: 6em;
							}
						}
					}
				}
			}
		}
	}

	.select2-results__option--highlighted[aria-selected] {
		color: #0877cc;
		background-color: #eff1f5;
	}

	.select2-results__group {
		display: block;

		padding: 6px;

		cursor: default;
	}
}

.select2-container--acct_registration.select2-container--open {
	.select2-dropdown {
		border: 1px solid #d3dbe0;
		border-radius: 3px;
		background: #fff;
		box-shadow: 0 2px 3px 0 rgba(0,0,0,.12);
	}
	.select2-dropdown--above {
		top: -4px;
	}

	.select2-dropdown--below {
		top: 4px;
	}
}

.select2-container--acct_registration.select2-has-error {
	.select2-selection.select2-selection--single {
		border-color: #e4243e;
		&:focus {
			border-color: #2193cc;
			box-shadow: 0 0 8px 0 #c1d4ee;

			&:hover {
				border-color: #2193cc;
				box-shadow: 0 0 8px 0 #c1d4ee;
			}
			.select2-selection__rendered {
				opacity: 1;
			}
		}
		&:hover {
			border-color: #909599;
			.select2-selection__rendered {
				opacity: 1;
			}
		}
	}
}
