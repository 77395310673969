.experience__wrapper {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;

	@include media-query($_1280) {
		background-image: url(/images/s2-bg.png);
		background-repeat: no-repeat;
		background-position: calc(50% + 700px) center;
	}
	@include media-query(1400px) {
		background-size: auto 90%;
	}
	@include media-query($_1680) {
		background-position: calc(50% + 315px) center;
		background-size: auto;
	}
	@include media-query($_1920) {
		background-position: calc(50% + 435px) center;
	}
}
.experience {
	padding: 0 24px 0;

	@include media-query($_720) {
		padding: 0 60px 0;
	}
	@include media-query($_1280) {
		padding: 0 80px 0;
	}
	@include media-query($_1680) {
		padding: 0 150px 0;
	}
	@include media-query($_1920) {
		padding-bottom: 0;
	}
	&__title {
		margin-bottom: 85px;

		color: #fff;

		@include media-query($_720) {
			margin-bottom: 113px;
		}
		@include media-query($_1024) {
			width: 70%;
		}
		@include media-query($_1280) {
			margin-bottom: 102px;
		}
		@include media-query($_1680) {
			width: 80%;
			margin-bottom: 258px;
		}
	}

	&__list {
		@include media-query($_720) {
			display: flex;
			flex-direction: row;

			justify-content: space-between;
			flex-wrap: wrap;
		}
		@include media-query($_1280) {
			justify-content: flex-start;
		}
		&__item {
			position: relative;

			margin-bottom: 47px;

			@include media-query($_720) {
				width: 45%;
				margin-bottom: 59px;
			}
			@include media-query($_1280) {
				width: 28%;
				margin-bottom: 89px;
				&:nth-child(3n+2) {
					margin-right: 8%;
					margin-left: 8%;
				}
				&:nth-last-child(-n+3) {
					margin-bottom: 0;
				}
			}
			@include media-query($_1680) {
				width: 20%;
				margin-bottom: 119px;
				&:nth-child(3n+2) {
					margin-right: 20%;
					margin-left: 20%;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__title {
			font-size: 20px;
			font-weight: 500;
			line-height: 1.5;

			display: block;

			margin-bottom: 31px;
			margin-left: 36px;

			color: #9fe1d8;

			@include media-query($_1680) {
				margin-bottom: 31px;
			}
			@include media-query($_1680) {
				margin-bottom: 31px;
			}
		}
		&__text {
			font-size: 16px;
			// font-weight: 500;
			line-height: 1.5625;

			color: #fff;
		}
		&__icon {
			position: absolute;
			top: 3px;
			left: 0;
		}
	}
}
