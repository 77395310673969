.acct__wrapper {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;

	background: #a6ddd6;
}
.acct {
	padding: 0 24px 0;

	@include media-query($_720) {
		padding: 0 60px 0;
	}
	@include media-query($_1280) {
		display: flex;
		flex-direction: row;

		padding: 0 80px 0;

		justify-content: space-between;
		flex-wrap: wrap;
	}
	@include media-query($_1680) {
		padding: 0 150px 0;
	}
	@include media-query($_1920) {
		padding-bottom: 0;
	}
	&__inner {
		margin-bottom: 90px;

		@include media-query($_720) {
			margin-bottom: 150px;
		}
		@include media-query($_1280) {
			width: 45%;
			margin-bottom: 0;
		}
		@include media-query($_1680) {
			width: 60%;
		}
	}
	&__title {
		margin-bottom: 42px;

		@include media-query($_1680) {
			margin-bottom: 60px;
		}
	}
	&__text {
		font-size: 18px;
		line-height: 1.5;

		margin-bottom: 51px;

		@include media-query($_720) {
			font-size: 20px;
		}
		@include media-query($_1024) {
			width: 75%;
		}
		@include media-query($_1280) {
			width: 100%;
		}
		@include media-query($_1680) {
			margin-bottom: 75px;
		}
	}
	&__link {
		font-size: 24px;
		line-height: 1.5;

		margin-bottom: 49px;

		text-decoration: underline;

		color: #9fe1d8;

		@include media-query($_1280) {
			margin-bottom: 0;
		}
		@include media-query($_1680) {
			font-size: 42px;
			line-height: 1.3;
		}
		&:hover,
		&:focus {
			text-decoration: none;

			color: #fff;
		}
	}
	&__address {
		font-size: 16px;
		line-height: 1.5;

		color: #fff;

		@include media-query($_1280) {
			display: flex;
			flex-direction: column;

			width: 24%;

			flex-wrap: wrap;
			justify-content: center;
		}
		@include media-query($_1920) {
			width: 21%;
		}
		&__inner {
			margin-bottom: 26px;

			opacity: .64;
		}
		&__link {
			margin-right: 50%;

			opacity: .64;
			color: #fff;

			@include media-query($_1280) {
				margin-right: auto;
			}
			&:last-child {
				text-decoration: underline;
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
			&:hover,
			&:focus {
				opacity: 1;
			}
		}
	}
	&__contacts__wrapper {
		@include media-query($_1280) {
			display: flex;
			flex-direction: column;

			justify-content: center;
		}
	}
	&__contacts {
		padding: 27px 30px 36px;

		border-radius: 4px;
		background: #ecf4f8;

		@include media-query($_720) {
			padding: 57px 60px 69px;
		}
		@include media-query($_1280) {
			width: 425px;
		}


		&__title {
			font-size: 20px;

			margin-bottom: 35px;
		}
		&__subtitle {
			font-size: 16px;

			display: block;

			margin-bottom: 11px;

			color: #0c4972;
		}
		&__link {
			font-size: 18px;
			font-weight: 500;

			display: inline-block;

			margin-bottom: 39px;

			color: #0f8595;

			@include media-query($_720) {
				font-size: 20px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
