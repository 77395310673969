.select2-selection--single {
	position: relative;

	height: auto;

	border-bottom: 1px solid #7b9cb3;
	border-radius: 0;
	background-color: #fff;
	&:before,
	&:after {
		position: absolute;

		display: block;

		width: 1px;
		height: 13px;

		content: '';

		background: green;
	}

	&:before {
		bottom: 0;
		left: 0;
	}
	&:after {
		right: 0;
		bottom: 0;
	}
	&:focus {
		border-color: #0c4972;
		outline: none;
		.select2-selection__rendered {
			color: #0f8595;
		}
	}
	&:focus:hover {
		.select2-selection__rendered {
			color: #0f8595;
		}
	}
	&:hover {
		// border-color: #0c4972;
		.select2-selection__rendered {
			color: #0f8595;
		}
	}

	.select2-selection__rendered {
		font-size: 18px;
		line-height: 1;

		padding-top: 9px;
		padding-right: 36px;
		padding-bottom: 9px;
		padding-left: 9px;

		color: #0c4972;

		@include media-query($_720) {
			font-size: 20px;

			padding-top: 10px;
			padding-bottom: 10px;
		}
		.flag-icon {
			margin-right: 10px;
		}
	}

	.select2-selection__clear {
		font-weight: bold;

		float: right;

		cursor: pointer;
	}

	.select2-selection__placeholder {
		color: #999;
	}

	.select2-selection__arrow {
		position: absolute;
		top: 50%;
		right: 10px;

		width: 12px;
		height: 7px;
		margin-top: -5px;

		transform: scale(1, -1);

		svg {
			position: absolute;
			top: 0;
			left: 0;

			width: 12px;
			height: 7px;

			fill: $blue;
		}
	}
}

&[dir='rtl'] {
	.select2-selection--single {
		.select2-selection__clear {
			float: left;
		}

		.select2-selection__arrow {
			right: auto;
			left: 1px;
		}
	}
}

&.select2-container--disabled {
	.select2-selection--single {
		cursor: default;

		background-color: #eee;

		.select2-selection__clear {
			display: none;
		}
	}
}

&.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			transform: scale(1, 1);
		}
	}
}
