// SITE
*,
*::before,
*::after {
	box-sizing: border-box;
}

html.site,
body.site {
	font-family: 'Inter';

	width: 100%;
	min-width: 320px;
	height: 100%;

	color: #0c4972;
}

// REGISTRATION
body.registration {
	line-height: 1;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1;

		margin: 0;
	}
	p {
		margin: 0;
	}
}

html.registration,
body.registration {
	font-family: 'Roboto', sans-serif;

	min-width: 1280px;
	height: 100%;

	background: #f1f2f5;
}
body.registration {
	display: flex;
	flex-direction: column;

	background: #f1f2f5;
}
