.exchange__wrapper {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
}
.exchange {
	padding: 0 24px 0;

	@include media-query($_720) {
		padding: 0 60px 0;
	}
	@include media-query($_1024) {
		display: flex;
		flex-direction: row;

		flex-wrap: nowrap;
		justify-content: space-between;
	}
	@include media-query($_1280) {
		padding: 0 80px 0;
	}
	@include media-query($_1680) {
		padding: 0 150px 0;
	}
	@include media-query($_1920) {
		padding-bottom: 0;
	}
	&__inner {
		@include media-query($_1024) {
			width: 45%;
		}
		@include media-query($_1280) {
			width: 50%;
		}
		@include media-query($_1680) {
			width: 55%;
		}
		@include media-query($_1920) {
			width: 55%;
		}
	}
	&-calculator {
		max-width: 360px;
		min-height: 400px;

		background: #fff;

		@include media-query($_1024) {
			display: flex;
			flex-direction: column;

			width: 45%;

			justify-content: center;
		}
		@include media-query($_1680) {
			width: 445px;
			max-width: 445px;
		}
	}

	&__title {
		margin-bottom: 55px;

		@include media-query($_1280) {
			width: 60%;
			margin-bottom: 66px;
		}
		@include media-query($_1680) {
			width: 100%;
			margin-bottom: 77px;
		}
	}
	&__text {
		margin-bottom: 62px;

		@include media-query($_720) {
			margin-bottom: 92px;
		}
		@include media-query($_1024) {
			margin-bottom: 0;
		}
		br {
			display: none;

			@include media-query($_720) {
				display: inline;
			}
			@include media-query($_1024) {
				display: none;
			}
			@include media-query($_1280) {
				display: inline;
			}
		}
		&--big {
			font-size: 24px;
			line-height: 1.5;

			margin-bottom: 49px;

			@include media-query($_720) {
				margin-bottom: 61px;
			}
			@include media-query($_1680) {
				font-size: 30px;
				line-height: 1.4;

				margin-bottom: 73px;
			}
		}
	}
}
#section-currency-exchange {
	border: 1px solid #0c4972;
}
