.swiper-greeting {
	position: absolute;
	z-index: 20;
	top: 0;
	right: 0;
	left: 0;
	.slide-1 {
		display: block;

		background-image: url('/images/img_head_3.jpg');
		background-position: center top;
		background-size: cover;
	}
	.slide-2 {
		display: block;

		background-image: url('/images/img_head_1.jpg');
		background-position: center top;
		background-size: cover;
	}
	.slide-3 {
		display: block;

		background-image: url('/images/img_head_2.jpg');
		background-position: center top;
		background-size: cover;
	}
	.slide-4 {
		display: block;

		background-image: url('/images/img_head_4.jpg');
		background-position: center top;
		background-size: cover;
	}
}
