header {
  position: fixed;
  z-index: 200;
  top: 0;

  width: 100%;

  transition: background .3s ease;
  transition-delay: .0;

  opacity: 1;

  .header-inner {
    display: flex;
    flex-direction: row;

    width: 100%;
    padding-right: 24px;
    padding-left: 24px;

    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    @include media-query($_720) {
      padding: 5px 36px;
    }
    @include media-query($_1280) {
      padding-right: 40px;
      padding-left: 40px;
    }

    a.logo {
      position: relative;
      top: -1px;

      margin-right: auto;
      padding: 15px 0;

      transition: all .2s;

      fill: #fff;

      svg {
        line-height: 0;

        display: inline-block;

        width: 82px;
        height: 30px;
      }
    }

    a.header-link {
      font-size: 16px;

      display: none;

      padding: 24px 14px;

      transition: all .2s;

      opacity: 1;
      color: #fff;

      @include media-query($_480) {
        position: relative;

        display: inline;
      }
    }

    button {
      position: relative;
      top: 1px;

      width: 34px;
      height: 28px;
      margin: 0 -5px 0 29px;
      padding: 5px;

      cursor: pointer;

      border: none;
      background: none;

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      .button-line {
        position: absolute;

        display: block;

        height: 2px;

        transition: all .2s;

        background: #fff;

        &:nth-child(1) {
          top: 5px;
          right: 5px;
          left: 5px;
        }

        &:nth-child(2) {
          top: 50%;
          right: 5px;
          left: 5px;

          margin-top: -1px;
        }

        &:nth-child(3) {
          right: 5px;
          bottom: 5px;
          left: 5px;
        }
      }
    }
  }
}


header.active1 {
  background: #9fe1d8;
}

header.active2 {
  background: none;
}

header.active3 {
  background: #061926;
}

header.active4 {
  background: #dee8ed;
}

header.active5 {
  background: #fff;
}

header.active6 {
  background: #9fe1d8;
}

header.active7 {
  background: #061926;
}

header.active8 {
  background: #dee8ed;
}

header.active9 {
  background: #fff;
}

header.active1,
header.active4,
header.active5,
header.active6,
header.active8,
header.active9 {
  a.logo svg path.text {
    fill: #0c4972;
  }

  a.logo svg path.unit {
    fill: #0f8595;
  }
}

header.active2,
header.active3,
header.active7 {
  a.logo svg path.text {
    fill: #fff;
  }

  a.logo svg path.unit {
    fill: #fff;
  }
}

.js-main-menu {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  width: 280px;
  padding-top: 25px;
  padding-right: 40px;
  padding-bottom: 16px;
  padding-left: 40px;

  transition: transform .3s ease;
  transform: translate(280px, 0);

  background: #02080b;

  flex-wrap: nowrap;

  button {
    font-size: 16px;
    line-height: 1;

    display: block;

    width: 100%;
    margin-bottom: 24px;
    padding: 18px 0 18px;

    cursor: pointer;
    transition: all .3s;

    color: #fff;
    border: 1px solid rgba(255, 255, 255, .36);
    border-radius: 4px;
    background: none;

    @include media-query($_480) {
      padding: 18px 0;
    }

    &:not(.btn-menu-close):hover {
      color: rgb(2, 8, 11);
      border: 1px solid rgba(255, 255, 255, 1);
      background: #fff;;
    }
  }

  a {
    display: block;

    padding: 16px 0;

    transition: all .2s;

    opacity: .64;
    color: #fff;

    &:nth-child(8) {
      margin-bottom: 20px;
    }

    &.active,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;

      opacity: 1;
      outline: none;
    }
  }

  a.additional {
    font-size: 14px;
    line-height: 1;

    display: inline-block;

    width: 100%;
    padding-top: 11px;
    padding-bottom: 10px;

    text-decoration: underline;

    opacity: 1;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &.shown {
    transform: translate(0, 0);
  }

  button.btn-menu-close {
    position: absolute;
    z-index: 2005;
    top: 16px;
    right: 16px;

    display: inline-block;

    width: auto;
    margin: 0;
    padding: 10px;

    opacity: .64;
    border: none;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      outline: none;
    }
  }
}

header {
  &.a2 {
    background: #061926;
  }

  &.a3 {
    background: #dee8ed;

    button .button-line {
      background: #0c4972;
    }

    a.header-link {
      color: #0c4972;
    }

    a.logo svg {
      fill: #0c4972;
    }
  }

  &.a4 {
    background: #fff;

    button .button-line {
      background: #0c4972;
    }

    a.header-link {
      color: #0c4972;
    }

    a.logo svg {
      fill: #0c4972;
    }
  }

  &.a5 {
    background: #dee8ed;

    button .button-line {
      background: #0c4972;
    }

    a.header-link {
      color: #0c4972;
    }

    a.logo svg {
      fill: #0c4972;
    }
  }

  &.a6 {
    background: #061926;
  }

  &.a7 {
    background: #dee8ed;

    button .button-line {
      background: #0c4972;
    }

    a.header-link {
      color: #0c4972;
    }

    a.logo svg {
      fill: #0c4972;
    }
  }

  &.a8 {
    background: #dee8ed;

    button .button-line {
      background: #0c4972;
    }

    a.header-link {
      color: #0c4972;
    }

    a.logo svg {
      fill: #0c4972;
    }
  }

  &.a9 {
    background: #fff;

    button .button-line {
      background: #0c4972;
    }

    a.header-link {
      color: #0c4972;
    }

    a.logo svg {
      fill: #0c4972;
    }
  }
}
