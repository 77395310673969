.btn {
	&.btn-default {
		font-size: 20px;
		font-weight: 500;
		line-height: 1;

		display: inline-block;

		width: 100%;
		padding: 35px 0;

		cursor: pointer;
		transition: all .3s;
		text-align: center;
		text-decoration: none;
		// letter-spacing: .7px;

		color: #0a3b5c;
		border: none;
		border-radius: 4px;
		background: #fff;

		@include media-query($sm) {
			max-width: 300px;
		}

		&:hover,
		&:focus {
			color: #fff;
			background: $blue;
		}
		&:active {
			background: #063c61;;
		}
	}
}
