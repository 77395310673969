.select2-selection--multiple {
	cursor: text;

	border: 1px solid #aaa;
	border-radius: 0;
	background-color: white;

	.select2-selection__rendered {
		box-sizing: border-box;
		width: 100%;
		margin: 0;
		padding: 0 5px;

		list-style: none;

		li {
			list-style: none;
		}
	}

	.select2-selection__placeholder {
		float: left;

		margin-top: 5px;

		color: #999;
	}

	.select2-selection__clear {
		font-weight: bold;

		float: right;

		margin-top: 5px;
		margin-right: 10px;

		cursor: pointer;
	}

	.select2-selection__choice {
		float: left;

		margin-top: 5px;
		margin-right: 5px;
		padding: 0 5px;

		cursor: default;

		border: 1px solid #d3dbe0;
		border-radius: 3px;
		background-color: #e4e4e4;
	}

	.select2-selection__choice__remove {
		font-weight: bold;

		display: inline-block;

		margin-right: 2px;

		cursor: pointer;

		color: #999;

		&:hover {
			color: #333;
		}
	}
}

&[dir='rtl'] {
	.select2-selection--multiple {
		.select2-selection__choice,
		.select2-selection__placeholder,
		.select2-search--inline {
			float: right;
		}

		.select2-selection__choice {
			margin-right: auto;
			margin-left: 5px;
		}

		.select2-selection__choice__remove {
			margin-right: auto;
			margin-left: 2px;
		}
	}
}

&.select2-container--focus {
	.select2-selection--multiple {
		border: solid black 1px;
		outline: 0;
	}
}

&.select2-container--disabled {
	.select2-selection--multiple {
		cursor: default;

		background-color: #eee;
	}

	.select2-selection__choice__remove {
		display: none;
	}
}
