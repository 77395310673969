.price__wrapper {
	padding-top: 25px;

	@include media-query($_720) {
		padding-top: 29px;
		padding-bottom: 15px;
	}
}

.price {
	position: relative;

	padding-top: 26px;
	padding-bottom: 27px;

	opacity: 1;
	border-bottom: 1px solid #cfdbe3;

	@include media-query($_720) {
		display: flex;
		flex-direction: row;

		padding-top: 35px;
		padding-bottom: 36px;

		flex-wrap: nowrap;
		justify-content: space-between;
	}
	@include media-query($_1680) {
		padding-top: 47px;
		padding-bottom: 48px;
	}
	&:last-child {
		border-bottom: none;

		@include media-query($_720) {
			border-bottom: 1px solid #cfdbe3;
		}
	}
	&:first-child {
		@include media-query($_720) {
			border-top: 1px solid #cfdbe3;
		}
	}




	&__icon {
		position: absolute;
		top: 28px;
		right: 0;

		@include media-query($_720) {
			top: 37px;
			right: auto;
			left: 0;
		}
		@include media-query($_1680) {
			top: 47px;
			left: 2px;
		}
	}
	&__link {
		font-size: 12px;
		font-weight: 500;
		line-height: 100%;

		position: relative;

		display: inline-block;

		padding: 6px 8px 6px 25px;

		color: #0f8595;
		border: 1px solid rgba(15,133,149, .5);
		border-radius: 4px;

		align-items: center;
		&:hover,
		&:focus {
			text-decoration: none;

			border: 1px solid rgba(15,133,149, 1);
		}
		&:active {
			background: rgba(15, 133, 149, .1);
		}
		&-icon {
			position: absolute;
			top: 4px;
			left: 7px;
		}
	}

	> i {
		font-size: 12px;
		font-weight: 500;
		line-height: 100%;

		display: inline-block;

		padding: 7px 8px;

		color: #0f8595;
		border: none;
		border-radius: 4px;
		background: rgba(15, 133, 149, .1);

		align-items: center;
	}

	&__title {
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;

		display: block;

		margin-bottom: 10px;

		color: #0c4972;

		@include media-query($_720) {
			margin-bottom: 0;
			margin-left: 40px;
		}
	}
}
