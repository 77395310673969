.graph-wrapper {
	background: #fff;
	h3 {
		font-size: 24px;

		margin-bottom: 39px;
	}
	label.radio {
		display: block;

		width: 100%;

		color: #0c4972;
		&:last-child {
			margin-bottom: 42px!important;
		}
	}
}
.container-exchange {
	display: block;

	width: 100%;
	form {
		margin-bottom: 21px;

		text-align: left;

		@include media-query($_1680) {
			margin-bottom: 32px;
		}

		label {
			font-size: 12px;

			width: 100%;
			margin-bottom: 6px;

			color: #0c4972;
		}
		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			margin: 0;

			-webkit-appearance: none;
		}
		input[type='number'] {
			font-family: $open-sans;
			font-family: 'Inter';
			font-size: 18px;
			font-weight: 400;
			line-height: 1;

			width: calc(100% - 90px);
			margin-bottom: 0;
			padding-top: 9px;
			padding-right: 10px;
			padding-bottom: 9px;
			padding-left: 10px;

			color: #0f8595;
			color: #0c4972;
			border-top: none;
			border-right: none;
			border-bottom: 1px solid #7b9cb3;
			border-left: none;
			background: none;

			@include placeholder {
				opacity: 1;
				color: #86a4b9;
			}
			@include media-query($_720) {
				font-size: 20px;

				padding-top: 8px;
				padding-bottom: 8px;
			}

			&:focus {
				border-color: #0c4972;
				outline: none;
			}
		}
		> div {
			position: relative;

			display: flex;
			flex-direction: row;

			margin-bottom: 24px;

			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: flex-end;
			@include media-query($_1680) {
				margin-bottom: 32px;
			}

			&:last-child {
				margin-bottom: 0;
			}
			&:before,
			&:after {
				position: absolute;

				width: 1px;
				height: 13px;

				content: '';

				background: green;
			}
			&:before {
				bottom: 0;
				left: 0;
			}
			&:after {
				right: 0;
				bottom: 0;
			}
		}
		select {
			opacity: 0;
		}
	}
}
.exchange__inner {
	@include media-query($_1024) {
		display: flex;
		flex-direction: column;

		flex-wrap: nowrap;
		justify-content: center;
	}
}
.exchange-wrapper {
	padding: 30px 32px 33px;

	@include media-query($_1680) {
		padding: 73px 70px 73px;
	}

	.radio-wrapper {
		margin-bottom: 26px;
		padding-bottom: 9px;

		border-bottom: 1px solid #cfdbe3;

		@include media-query($_720) {
			margin-bottom: 24px;
		}
		@include media-query($_1680) {
			margin-bottom: 48px;
		}
	}
}

.container-exchange {
	.text-header,
	.text-rates,
	.text-commision,
	.text-total,
	.text-footer {
		display: flex;

		text-align: left;

		font-display: row;
		justify-content: space-between;
		align-items: center;
		span {
			font-size: 12px;
			font-weight: normal;

			color: #0c4972;
		}
		span.sep {
			position: relative;

			width: 20px;

			flex-grow: 2;
			&:before {
				position: absolute;
				top: 50%;
				right: 12px;
				left: 12px;

				height: 1px;

				content: '';

				opacity: .24;
				background: $blue;
			}
		}
	}
	.text-header {
		margin-bottom: 19px;
		span:last-child {
			text-transform: none;

			color: $blue;
		}
	}
	.text-rates {
		span.rate-info {
			font-size: 15px;

			position: relative;
			top: 3px;

			padding: 7px 8px 8px 9px;

			color: #fff;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom-left-radius: 4px;
			background: #0f8595;

			&:after {
				position: absolute;
				right: 0;
				bottom: -8px;

				display: block;
				display: block;

				width: 0;
				height: 0;

				content: '';

				border: 5px solid transparent;
				border-top: 5px solid #0f8595;
				border-right: 5px solid #0f8595;
			}
		}
		span.sep {
			opacity: 1;
		}
		span:first-child {
			color: $blue;
		}
	}
	.g-box {
		height: 81px;
		margin-bottom: 12px;

		border-right: 1px solid #cfdbe3;

		@include media-query($_1680) {
			height: 96px;
		}
	}
	.text-commision {
		margin-bottom: 24px;

		@include media-query($_1680) {
			margin-bottom: 32px;
		}
		span:first-child,
		span:last-child {
			color: $blue;
		}
		span:last-child {
			text-transform: none;
		}
	}
	.text-footer {
		margin-bottom: 16px;
		span {
			span {
				opacity: .38;
			}
		}
	}
	.text-total {
		color: #0c4972;
		span {
			font-size: 14px;
			line-height: 1.5;

			overflow: hidden;
		}
	}
	input[type='number'] + svg {
		line-height: 0;

		position: absolute;
		right: 92px;
		bottom: 8px;

		display: inline-block;

		width: 12px;
		height: 17px;
		margin-top: -8px;

		opacity: .4;

		fill: #51fee0;
	}
	.select2-has-arrow.active {
		input[type='number'] + svg {
			opacity: 1;
		}
	}
	.exchange-result-title {
		font-size: 12px;

		width: 100%;
		margin-bottom: 12px;

		color: #0c4972;

		@include media-query($_1680) {
			margin-bottom: 20px;
		}
	}
	.exchange-result {
		font-size: 30px;

		overflow: hidden;

		margin-bottom: 9px;

		white-space: nowrap;
		text-overflow: ellipsis;

		color: #0c4972;
		span {
			font-size: 20px;

			margin-left: 8px;
		}
	}
}
