%container {
  min-width: 320px;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;

  @include media-query($_720) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @include media-query($_1280) {
    padding-right: 80px;
    padding-left: 80px;
  }
  @include media-query($_1680) {
    padding-right: 150px;
    padding-left: 150px;
  }
}

.heading {
  background: #061926;

  text-align: center;
  padding-top: 112px;
  padding-bottom: 50px;

  @include media-query($_720) {
    padding-top: 143px;
    padding-bottom: 83px;
  }
  @include media-query($_1920) {
    padding-top: 205px;
    padding-bottom: 124px;
  }

  &__inner {
    @extend %container;
  }

  &__title {
    color: #fff;
    font-size: 42px;
    line-height: 1.1428;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: -1px;
    @include media-query($_720) {
      font-size: 64px;
      line-height: 1.125;
      margin-bottom: 42px;
    }
    @include media-query($_1920) {
      font-size: 84px;
      margin-bottom: 52px;
      letter-spacing: -1.8px;
    }
  }

  &__update {
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
  }


}