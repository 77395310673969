.select2-selection--single {
	position: relative;

	height: auto;

	border-bottom: 1px solid green;
	border-radius: 0;
	background-color: none;
	&:before {
		position: absolute;

		display: block;

		width: 1px;
		height: 13px;

		content: '';

		background: green;
	}

	&:before {
		bottom: 0;
		left: 0;
	}
	&:focus {
		outline: none;
		.select2-selection__rendered {
			color: green;
		}
	}
	&:hover {
		.select2-selection__rendered {
			color: green;
		}
	}

	.select2-selection__rendered {
		line-height: 1;

		padding-top: 10px;
		padding-right: 36px;
		padding-bottom: 10px;
		padding-left: 10px;

		color: #fff;
		.flag-icon {
			margin-right: 10px;
		}
	}

	.select2-selection__clear {
		font-weight: bold;

		float: right;

		cursor: pointer;
	}

	.select2-selection__placeholder {
		color: #999;
	}

	.select2-selection__arrow {
		position: absolute;
		top: 50%;
		right: 12px;

		width: 12px;
		height: 7px;
		margin-top: -2px;

		transform: scale(1, -1);

		svg {
			position: absolute;
			top: 0;
			left: 0;

			width: 12px;
			height: 7px;

			fill: aqua;
		}
	}
}

&[dir='rtl'] {
	.select2-selection--single {
		.select2-selection__clear {
			float: left;
		}

		.select2-selection__arrow {
			right: auto;
			left: 1px;
		}
	}
}

&.select2-container--disabled {
	.select2-selection--single {
		cursor: default;

		background-color: #eee;

		.select2-selection__clear {
			display: none;
		}
	}
}

&.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			transform: scale(1, 1);
		}
	}
}
