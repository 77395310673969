.preview__wrapper {
	min-width: 320px;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
}
.preview {
	padding: 0 24px 0;

	@include media-query($_720) {
		padding: 0 60px 0;
	}
	@include media-query($_1280) {
		display: flex;
		flex-direction: row;

		padding: 0 80px 0;

		justify-content: space-between;
		flex-wrap: wrap;
	}
	@include media-query($_1680) {
		padding: 0 150px 0;
	}
	@include media-query($_1920) {
		padding-bottom: 0;
	}
	&__inner {
		@include media-query($_1280) {
			display: flex;
			flex-direction: column;

			width: 45%;

			justify-content: center;
		}
		@include media-query($_1680) {
			width: 47%;
		}
	}
	&__title {
		font-size: 42px;
		line-height: 1.15;

		margin-bottom: 60px;

		color: #0c4972;

		@include media-query($_720) {
			font-size: 64px;
		}
		@include media-query($_1280) {
			margin-bottom: 71px;
		}
		@include media-query($_1680) {
			font-size: 84px;

			margin-bottom: 78px;
		}
	}
	&__subtitle {
		font-size: 20px;
		font-weight: 500;

		display: block;

		margin-bottom: 42px;

		color: #0c4972;

		@include media-query($_1280) {
			width: 50%;
			margin-bottom: 66px;
		}
	}
	&__text {
		margin-bottom: 62px;

		@include media-query($_720) {
			margin-bottom: 92px;
		}
		@include media-query($_1024) {
			padding-right: 25%;
		}
		@include media-query($_1280) {
			margin-bottom: 0;
			padding-right: 0;
		}
	}
	&__list-item {
		position: relative;

		display: block;

		margin-bottom: 12px;
		padding-right: 25px;
		padding-left: 25px;

		@include media-query($_720) {
			margin-bottom: 0;
		}
		&:first-child {
			margin-top: 25px;
		}
		&:last-child {
			margin-bottom: 25px;
		}
		svg {
			position: absolute;
			top: 7px;
			left: 0;
		}
	}
	&__img-wrapper {
		width: 100%;

		@include media-query($_720) {
		}
		@include media-query($_1280) {
			display: flex;
			flex-direction: column;

			width: 50%;

			order: -1;
			justify-content: center;
		}
		@include media-query($_1680) {
			width: 47%;

			order: -1;
		}
		img {
			width: 100%;
			max-width: 740px;
			height: auto;

			@include media-query($_1280) {
				max-width: 100%;
			}
		}
	}
}
