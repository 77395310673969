.commisions {
	position: relative;

	overflow: hidden;

	padding: 30px 32px 33px;

	@include media-query($_1680) {
		padding: 67px 70px 73px;
	}
	&__title {
		font-size: 24px;
		line-height: 1.5;

		display: block;

		margin-bottom: 8px;

		color: #0c4972;

		@include media-query($_720) {
			br {
				display: none;
			}
		}
		@include media-query($_1680) {
			font-size: 30px;
		}
	}
	&__toggle__btn {
		font-size: 16px;
		font-weight: 500;
		line-height: 1;

		display: flex;
		flex-direction: row;

		margin-bottom: 24px;
		padding: 0;

		cursor: pointer;

		color: #0f8595;
		border: none;
		background: none;

		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		@include media-query($_720) {
		}
		@include media-query($_1680) {
			margin-bottom: 49px;
		}
		&:hover,
		&:focus,
		&:active {
			outline: none;
		}
		span {
			font-weight: 500;
		}
		span:last-child {
			display: none;
		}
		.svg_search {
			margin-right: 8px;

			opacity: 1;
		}
		.svg_back {
			display: none;

			margin-right: 8px;
		}
	}
	&__wrapper {
		position: relative;
	}

	&__cover {
		position: absolute;
		z-index: 10;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: flex;
		flex-direction: column;

		opacity: 1;
		background: #fff;

		flex-wrap: nowrap;
		justify-content: space-between;

		&__list {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			display: flex;
			flex-direction: column;

			flex-wrap: nowrap;
			justify-content: space-between;
			@include media-query($_720) {
				padding-top: 55px;
				padding-bottom: 20px;
			}
			@include media-query($_1680) {
				padding-top: 30px;
				padding-bottom: 0;
			}
			hr {
				line-height: 0;

				display: none;

				width: 100%;
				height: 1px;

				opacity: 1;
				border-color: #cfdbe3;
				border-top: none;
				border-right: none;
				border-left: none;

				@include media-query($_720) {
					display: block;
				}
			}

			&__item {
				@include media-query($_720) {
					display: flex;
					flex-direction: row;

					justify-content: space-between;
					align-items: center;
				}


				svg {
					display: inline-block;

					width: 44px;
					height: 44px;
					margin-bottom: 9px;

					@include media-query($_720) {
						margin-bottom: 0;
					}
					@include media-query($_1680) {
						width: 60px;
						height: 60px;
					}
				}
				span {
					font-size: 14px;
					line-height: 1.6;

					display: block;

					max-width: 270px;

					color: #0c4972;

					@include media-query($_720) {
						display: flex;

						width: 77%;
					}
					@include media-query($_1680) {
						font-size: 16px;

						width: 72%;
					}
				}
			}
		}
	}
}

.commisions-widget {
	&__label {
		font-size: 12px;
		line-height: 1;

		display: inline-block;

		margin-bottom: 9px;

		opacity: 1;
		color: #0c4972;

		@include media-query($_720) {
			margin-bottom: 5px;
		}
		@include media-query($_1680) {
			margin-bottom: 13px;
		}
	}
	&__select {
		opacity: 1;
	}
	&__select__wrapper {
		margin-bottom: 24px;

		@include media-query($_1680) {
			margin-bottom: 30px;
			&:nth-child(2) {
				margin-bottom: 27px;
			}
		}
	}
	&__transfer-type {
		font-size: 18px;
		line-height: 1;

		margin-bottom: 16px;
		padding-bottom: 9px;

		color: #0c4972;
		border-bottom: 1px solid #cfdbe3;

		@include media-query($_1680) {
			font-size: 20px;
		}
		button {
			font-size: 18px;
			line-height: 1;

			display: inline;

			margin-right: 8px;
			padding: 0;

			color: #0c4972;
			border: none;
		}
		span {
			display: none;
			&.shown {
				position: relative;

				display: inline;
				&:after {
					position: absolute;
					right: 0;
					bottom: -9px;
					left: 0;

					display: block;

					height: 1px;

					content: '';

					background: #507c99;
				}
			}
		}
		button {
			display: none;

			cursor: pointer;
			&:hover,
			&:focus,
			&:active {
				outline: none;
			}
			&.shown {
				position: relative;

				display: inline;
				&.active {
					&:after {
						position: absolute;
						right: 0;
						bottom: -10px;
						left: 0;

						display: block;

						height: 1px;

						content: '';

						background: #507c99;
					}
				}
			}
		}
	}
	&__total {
		font-size: 30px;

		margin-bottom: 19px;

		color: #0c4972;

		@include media-query($_720) {
			margin-bottom: 19px;
		}
		@include media-query($_1680) {
			font-size: 60px;

			margin-bottom: 9px;
		}
		span:last-child {
			font-size: 20px;

			@include media-query($_720) {
				font-size: 20px;
			}
			@include media-query($_1680) {
				font-size: 36px;
			}
		}
	}
	.tooltip__inner {
		font-size: 12px;

		position: relative;

		margin-bottom: 16px;

		color: #0c4972;

		@include media-query($_1680) {
			margin-bottom: 9px;
		}

		svg {
			cursor: pointer;
			&:hover,
			&:focus,
			&:active {
				g {
					opacity: .64;
				}
			}
		}
	}
	p {
		font-size: 12px;
		line-height: 18px;
		a {
			font-weight: 500;

			text-decoration: underline;

			color: #0f8595;
			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}
		}
	}
}
#sending-transfer-commission {
	display: block;
}
#payment-type {
	display: block;
}

.commisions.open {
	.commisions__cover {
		display: none;
	}
	.commisions__inner {
		display: block;

		max-width: 445px;
	}
	.commisions__toggle__btn {
		.svg_search {
			display: none;
		}
		.svg_back {
			display: inline-block;
		}
		span {
			display: none;
		}
		span:last-child {
			display: inline-block;
		}
	}
}
