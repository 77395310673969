@import '../Theme/constants';

.qrCodeHelp {
  min-height: 105 * $unit;
}

.qrCodeHelpButton {
  cursor: pointer;
}

.qrCodeHelpIcon {
  width: 20 * $unit;
  height: 16 * $unit;
  display: inline-block;
  vertical-align: text-bottom;
  content: '';
  background-repeat: no-repeat;
  background-image: url('./assets/icon-info.svg');
}

.qrCodeHelpList {
  font-size: $font-size-small;
  text-align: left;
  margin-bottom: 0;
}
