@import './constants';

.step {
  width: 100%;
  min-height: 100%;
  height: 0; /*necessary for IE11, see: https://stackoverflow.com/questions/24396205/flex-grow-not-working-in-internet-explorer-11-0*/
  position: relative;
  display: flex;
  flex-direction: column;
}

.fullScreenStep {
  overflow: hidden;
  border-radius: 8 * $unit;
}

.fullHeightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.fullHeightMobileContainer {
  @media (--small-viewport) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

.navigationBar {
  flex: 0 0 auto;
}

.content {
  /* Content height is 100% - non content height (total height of footer + total height of navbar) */
  $non-content-height: $footer-height + $footer-margin + $navigation-height +
    $navigation-padding-top;
  height: calc(100% - #{$non-content-height});
  position: relative;
  display: flex;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  > * {
    flex-grow: 1;
  }

  @media (--small-viewport) {
    $non-content-height: $footer-height + $footer-margin +
      $navigation-height-sm-screen + $navigation-padding-top-sm-screen;
    height: calc(100% - #{$non-content-height});
  }
}

.scrollableContent {
  flex: 1 1 auto; /* .content will fit remaining space */
  overflow: auto; /* scrollbar will only appear when child element exceeds .content height */
}

.fullScreenContentWrapper {
  position: static;
}

$footer-top-margin: 16 * $unit;
$cobrand-logo-width: 80 * $unit;

.cobrandFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: $footer-top-margin $large-text-margin 0;
  @media (--small-viewport) {
    margin: $footer-top-margin $small-text-margin 0;
  }

  .cobrandLabel {
    color: $color-neutral-700;
    font-size: 10 * $unit;
    display: flex;
    flex-direction: row;
    max-width: 80%;

    /* if "XXX powered by Onfido" exceeds the allowed space (with paddings on both sides of the screen), */
    /* then XXX should by truncated */
    .cobrandText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5 * $unit; /* space between XXX and " powered by" text */
      flex-grow: 1;
    }

    .poweredBy {
      flex-shrink: 0;
    }

    .fullScreenStep & {
      color: $color-white;
      z-index: 0; /* to make the text visible, otherwise it is hidden by overlay */
    }
  }

  .logo {
    background-image: url('./assets/onfido-logo.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: $cobrand-logo-width;
    height: $footer-height;
    margin-top: 0;
    flex: 0 0 auto;

    .fullScreenStep.cobrandLogo & {
      background-image: url('./assets/onfido-logo-light.svg');
      width: $cobrand-logo-width;
      margin-top: 0;
      z-index: 0; /* to make the logo visible, otherwise it is hidden by overlay */
    }
  }
}

.footer {
  width: 100%;
  height: $footer-height;
  margin-top: 16 * $unit;
  flex: 0 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  .defaultLogo & {
    background-image: url('./assets/watermark.svg');
  }

  .fullScreenStep.defaultLogo & {
    /* A full screen step will be a video/stream step, with a darker background */
    background-image: url('./assets/watermark_night.svg');
  }

  .noLogo & {
    background-image: none;
  }
}

.thickWrapper {
  margin: 0 $large-text-margin;
  @media (--small-viewport) {
    margin: 0 $small-text-margin;
  }
}

.mbottom-large {
  margin-bottom: 88 * $unit;
  @media (--small-viewport) {
    margin-bottom: 40 * $unit;
  }
}

.center {
  text-align: center;
}

.icon {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  height: 64 * $unit;
}

.header {
  border-radius: 10 * $unit-small;
  background-color: $color-tips-pill;
  color: $color-white;
  font-size: $font-size-small;
  font-weight: 600;
  position: absolute;
  margin-top: -10 * $unit-small;
  margin-left: 10 * $unit-small;
  padding: 0 14 * $unit-small;
}

.help {
  padding: 24 * $unit 16 * $unit 16 * $unit;
  text-align: left;
  box-shadow: inset 0 0 0 1 * $unit $color-help-container;
  border-radius: 8 * $unit;
  margin-bottom: 24 * $unit;
}

.helpList {
  margin: 0;
  padding-left: 24 * $unit;
}

.link {
  cursor: pointer;
  line-height: 1.43;
  font-size: $font-size-small;
  margin: auto;
  padding: 2px;
  text-decoration: none;
  border: 1px solid transparent;
  border-bottom-color: $color-primary-500;
  background-color: $color-transparent;
  color: $color-body-text;

  &:hover {
    background-color: $color-primary-button-hover;
    color: $color-white;
  }

  &:active {
    background-color: $color-primary-button-active;
    color: $color-white;
  }

  &:visited {
    text-decoration: none;
    color: $color-body-text;
    font-size: $font-size-small;
  }
}

.warning {
  background-color: transparent;

  &:hover {
    background-color: $color-primary-button-hover;
  }

  &:active {
    background-color: $color-primary-button-active;
  }
}

.error {
  background-color: transparent;

  &:hover,
  &:active {
    color: $color-error;
  }

  &:hover {
    background-color: $color-error-button-hover;
  }

  &:active {
    background-color: $color-error-button-active;
  }
}
