.download {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 2px;
  border-bottom: 1px solid #CFDBE3;
  padding-bottom: 35px;
  margin-bottom: 38px;

  @include media-query($_720) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  &__file {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    @include media-query($_720) {
      margin-bottom: 0;
    }

    &-icon {
      transform: translateY(-3px);
      margin-right: 8px;
    }

    &-name {
      transform: translateY(-2px);
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: .1px;
    }
  }

  &__button {
    border: 1px solid rgba(15, 133, 149, .5);
    text-decoration: none;

    border-radius: 4px;
    color: #0f8595;
    display: inline-block;
    padding: 2px 8px 6px 25px;
    position: relative;

    &:hover,
    &:focus {
      border: 1px solid #0f8595;
      text-decoration: none;
    }

    &:active {
      background: rgba(15, 133, 149, .1);
    }

    &-icon {
      position: absolute;
      top: 4px;
      left: 7px;
    }

    &-text {
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
    }
  }

  &--bottom {
    border-top: 1px solid #CFDBE3;
    border-bottom: none;
    margin-top: 41px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 36px;
    @include media-query($_720) {
      margin-top: 73px;
    }
  }
}